export default {
  SHARED: {
    COMPONENTS: {
      LICENSES: {
        INTRO_LINE: 'This software is made possible thanks to:',
        FULL_DISCLAIMER_LINK:
          'A full list of software that may be used in this program with their corresponding licenses ' +
          'is available <1>here</1>.',
        LICENSE: 'Released under the {{license}} license.',
        AUTHOR_WITH_LINK: 'By <1>{{vendorName}}</1>. ',
        AUTHOR_WITHOUT_LINK: 'By {{vendorName}}. '
      }
    }
  }
} as const
