import React, { useMemo } from 'react'
import { Link, Outlet, useLocation } from 'react-router-dom'
import { Trans, useTranslation } from 'react-i18next'

import {
  Delete as DeleteIcon,
  Edit as EditIcon,
  Add as AddIcon,
  People as PeopleIcon
} from '@mui/icons-material'
import { Fab, Grid, Grow, Paper, Typography, useMediaQuery, useTheme } from '@mui/material'

import MiniColoredChipList from 'components/MiniColoredChipList'
import AvatarListItemEntry from 'components/AvatarListItemEntry'
import FilterableList, { SortMenuEntry } from 'components/FilterableList'
import { useGroups } from 'lib/providers/GroupsProvider'
import { type Group } from 'lib/types/Group'

const groupsFilters: Record<string, (item: Group, filter: string) => boolean> = {
  name: (group: Group, s: string) => group.name.toLocaleLowerCase().includes(s.toLocaleLowerCase()),
  prefix: (group: Group, s: string) =>
    !!group.prefixes.find((prefix) => prefix.toLocaleLowerCase().includes(s.toLocaleLowerCase())),
  capability: (group: Group, s: string) =>
    !!group.capabilities.find((capability) =>
      capability.toLocaleLowerCase().includes(s.toLocaleLowerCase())
    )
}

/**
 * Groups page.
 *
 * Displays a list of groups as returned by `GetAllGroupObjects`.
 *
 * @constructor
 */
const GroupsList = () => {
  const { t } = useTranslation()

  const { pathname } = useLocation()

  const { getAllGroups, getError, getGroupsLoading, getGroupsRefetching } = useGroups()

  // Groups list returned from GraphQL call
  const groups = getAllGroups() ?? []
  // Any GraphQL errors
  const error = getError()
  const loading = getGroupsLoading()
  const refetching = getGroupsRefetching()

  const theme = useTheme()
  const fullscreen = useMediaQuery(theme.breakpoints.down('xs'))

  const sorts: SortMenuEntry<Group>[] = useMemo(
    () => [
      {
        label: t('PAGES.GROUPS.LIST.SORT_NAME'),
        sort: (a, b) => a.name.localeCompare(b.name)
      }
    ],
    [t]
  )

  return (
    <>
      <Grid
        container
        direction="column"
        alignItems="stretch"
        sx={{
          mx: 'auto',
          maxWidth: '725px',
          height: '100%',
          width: '100%'
        }}>
        <Paper
          elevation={2}
          sx={{
            m: 2,
            height: '100%',
            width: '95%'
          }}
          square={fullscreen}>
          <FilterableList
            itemHeight={110}
            sorts={sorts}
            filter={groupsFilters}
            filterTooltip={
              <>
                <Typography variant="body2" gutterBottom>
                  {t('PAGES.GROUPS.LIST.SEARCH_TOOLTIP_1')}
                </Typography>
                <Typography variant="body2">
                  <Trans i18nKey="PAGES.GROUPS.LIST.SEARCH_TOOLTIP_2">
                    ''
                    <Typography variant="monospace">''</Typography>
                  </Trans>
                </Typography>
              </>
            }
            title={t('PAGES.GROUPS.LIST.TITLE')}
            loading={loading}
            refetching={refetching}
            error={error}
            data={groups}
            renderItem={(group) => (
              <AvatarListItemEntry
                key={group.name}
                primary={group.name}
                secondary={
                  <>
                    {group.prefixes?.includes('') ? (
                      <MiniColoredChipList
                        items={[t('PAGES.GROUPS.DETAIL.PREFIXES_ALL_PREFIXES')]}
                        label={t('PAGES.GROUPS.DETAIL.PREFIXES_LABEL')}
                        variant="outlined"
                        short={true}
                      />
                    ) : (
                      <MiniColoredChipList
                        items={group.prefixes}
                        label={t('PAGES.GROUPS.DETAIL.PREFIXES_LABEL')}
                        labelIfEmpty={t('PAGES.GROUPS.DETAIL.PREFIXES_EMPTY_MESSAGE')}
                        emptyValue={t('PAGES.GROUPS.DETAIL.PREFIXES_EMPTY_PREFIX')}
                        short={true}
                      />
                    )}
                    <MiniColoredChipList
                      items={group.capabilities}
                      label={t('PAGES.GROUPS.DETAIL.CAPABILITIES_LABEL')}
                      labelIfEmpty={t('PAGES.GROUPS.DETAIL.CAPABILITIES_EMPTY_MESSAGE')}
                      short={true}
                    />
                  </>
                }
                avatarText={group.name}
                href={`/admin/groups/view/${group.name}`}
                menuEntries={[
                  [
                    {
                      icon: <PeopleIcon color="primary" />,
                      label: t('PAGES.GROUPS.LIST.BUTTON_DETAILS'),
                      href: `/admin/groups/view/${group.name}`
                    }
                  ],
                  [
                    {
                      icon: <EditIcon color="inherit" />,
                      label: t('PAGES.GROUPS.LIST.BUTTON_EDIT'),
                      href: `/admin/groups/edit/${group.name}`
                    }
                  ],
                  [
                    {
                      icon: <DeleteIcon color="warning" />,
                      label: t('PAGES.GROUPS.LIST.BUTTON_DELETE'),
                      href: `/admin/groups/delete/${group.name}`
                    }
                  ]
                ]}
              />
            )}
          />
        </Paper>
      </Grid>

      <Grow in={pathname === '/admin/groups'}>
        <Fab
          variant="extended"
          color="secondary"
          sx={{ position: 'absolute', bottom: '16px', right: '16px' }}
          component={Link}
          to={'/admin/groups/create'}>
          <AddIcon sx={{ mr: 1 }} />
          {t('PAGES.GROUPS.LIST.BUTTON_CREATE')}
        </Fab>
      </Grow>

      <Outlet />
    </>
  )
}

export default GroupsList
