import React from 'react'
import { Link as RouterLink, useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import {
  Divider,
  Drawer,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Toolbar,
  Box,
  useTheme,
  SvgIcon,
  ListSubheader,
  Collapse
} from '@mui/material'
import {
  Group as GroupIcon,
  Person as PersonIcon,
  Settings as SettingsIcon,
  Stream as StreamIcon
} from '@mui/icons-material'

import { useAuth } from 'lib/providers/AuthProvider'
import { GroupCapability } from 'lib/types/Group'
import { isIngressManagementEnabled } from 'lib/env'

interface NavButtonProps {
  label: string
  path: string
  /** Optional regex to test selection. If omitted, checks exact match on path */
  match?: RegExp
  icon: typeof SvgIcon
  subnav?: boolean
  disabled?: boolean
}

const NavButton: React.FC<NavButtonProps> = ({
  label,
  path,
  match,
  icon: Icon,
  disabled = false
}) => {
  const { pathname } = useLocation()
  const currentPath = match ? match.test(pathname) : path === pathname

  return (
    <ListItemButton
      selected={currentPath}
      key={label}
      component={RouterLink}
      to={path}
      disabled={disabled}
      sx={(theme) => ({
        flex: 0,
        borderLeft: `4px solid ${currentPath ? theme.palette.primary.main : 'transparent'}`,
        '& .MuiListItemIcon-root': {
          minWidth: '42px'
        }
      })}>
      <ListItemIcon>
        <Icon color={currentPath ? 'primary' : 'inherit'} />
      </ListItemIcon>
      <ListItemText color={currentPath ? 'primary' : 'inherit'} primary={label} />
    </ListItemButton>
  )
}

interface SideNavProps {
  expanded: boolean
  screenSize: 'sm' | 'md' | 'lg'
  manuallyExpanded: boolean
  width: number
  onClose: () => void
}

const SideNav: React.FC<SideNavProps> = ({
  expanded,
  screenSize,
  manuallyExpanded,
  width,
  onClose
}) => {
  const { t } = useTranslation()

  const { getLoggedIn, getPermissions } = useAuth()

  const theme = useTheme()
  const permissions = getPermissions()
  const isAdmin = permissions && permissions.includes(GroupCapability.ADMIN)

  const ingressesEnabled = isIngressManagementEnabled()

  const drawerWidthTransitionOpen = theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen
  })
  const drawerWidthTransitionClose = theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen
  })

  if (!getLoggedIn()) {
    return <></>
  }

  return (
    <Drawer
      open={true}
      variant="temporary"
      anchor="left"
      hideBackdrop={!manuallyExpanded}
      ModalProps={{ disableEnforceFocus: !manuallyExpanded }}
      onClose={onClose}
      elevation={manuallyExpanded || theme.palette.mode === 'dark' ? 8 : 2}
      sx={{
        width: width,
        flexShrink: 0,
        transition: expanded ? drawerWidthTransitionClose : drawerWidthTransitionOpen,
        zIndex: theme.zIndex.appBar - 1,
        '& .MuiDrawer-paper': {
          backgroundColor: (theme.palette.background as any).paper,
          boxSizing: 'border-box',
          width: width,
          transition: expanded ? drawerWidthTransitionClose : drawerWidthTransitionOpen,
          overflowX: 'hidden'
        }
      }}>
      <Toolbar />
      <Box sx={{ overflow: 'hidden auto', height: '100%' }}>
        <List
          sx={{
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
            pb: 0
          }}>
          <Collapse in={expanded || manuallyExpanded || screenSize !== 'md'}>
            {/* Wrapper box fixes an issue with expansion being too big 
            before snapping to the correct height */}
            <Box height="48px">
              <ListSubheader disableSticky sx={{ whiteSpace: 'nowrap' }}>
                {t('COMPONENTS.SIDE_NAV.USER_MANAGEMENT')}
              </ListSubheader>
            </Box>
          </Collapse>
          <NavButton
            label={t('COMPONENTS.SIDE_NAV.USERS')}
            path="/admin/users"
            match={/^\/admin\/users/}
            icon={PersonIcon}
            disabled={permissions && !isAdmin}
          />
          <NavButton
            label={t('COMPONENTS.SIDE_NAV.GROUPS')}
            path="/admin/groups"
            match={/^\/admin\/groups/}
            icon={GroupIcon}
            disabled={permissions && !isAdmin}
          />

          {ingressesEnabled && (
            <>
              <Divider sx={{ my: 2 }} />

              <Collapse in={expanded || manuallyExpanded || screenSize !== 'md'}>
                <Box height="48px">
                  {/* Wrapper box fixes an issue with expansion being too big 
            before snapping to the correct height */}
                  <ListSubheader disableSticky sx={{ whiteSpace: 'nowrap' }}>
                    {t('COMPONENTS.SIDE_NAV.STREAM_MANAGEMENT')}
                  </ListSubheader>
                </Box>
              </Collapse>
              <NavButton
                label={t('COMPONENTS.SIDE_NAV.INGRESSES')}
                path="/admin/ingress"
                match={/^\/admin\/ingress/}
                icon={StreamIcon}
                disabled={permissions && !isAdmin}
              />
            </>
          )}

          <Box flex={1} />
          <Divider />

          <NavButton
            label={t('COMPONENTS.SIDE_NAV.SETTINGS')}
            path="/admin/settings"
            match={/^\/admin\/settings/}
            icon={SettingsIcon}
          />
        </List>
      </Box>
    </Drawer>
  )
}

export default SideNav
