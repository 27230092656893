import { isProductionEnvironment } from './env'

export const retrieveCookie = (key: string): string | null => {
  const cookies = document.cookie.split(';').map((c) => c.split('='))
  const cookie = cookies.find(([k, _]) => k.trim() === key)?.[1]
  return cookie && decodeURI(cookie)
}

export const storeCookie = (key: string, value: any, maxAge: number = 864e10) => {
  const encodedKey = encodeURI(key)
  const encodedValue = encodeURI(value ?? '')
  const secure = isProductionEnvironment() ? 'Secure;' : ''

  const cookieString = `${encodedKey}=${encodedValue}; path=/; max-age=${maxAge}; SameSite=Lax; ${secure}`
  document.cookie = cookieString
}

export const clearCookie = (key: string) => {
  // Some cookies may be cross-domain cookies. We have no way to see which ones are,
  // so try and delete all levels of that cookie.
  // See https://stackoverflow.com/a/2959110/2197509

  // Start with no leading dot...
  document.cookie = `${key}=; path=/; max-age=0; domain=${window.location.hostname}`
  // ...then go up the domain heirarchy
  const domain = window.location.hostname.split('.')
  for (let i = 0; i < domain.length; i++) {
    document.cookie = `${key}=; path=/; max-age=0; domain=.${domain
      .slice(i, domain.length)
      .join('.')}`
  }
}
