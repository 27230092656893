import React, { useCallback, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import {
  AppBar,
  Toolbar,
  IconButton,
  Menu,
  Tooltip,
  MenuItem,
  Collapse,
  Box,
  ListItemIcon,
  ListItemText,
  Divider,
  styled
} from '@mui/material'
import { Menu as MenuIcon, Login as LoginIcon, Logout as LogoutIcon } from '@mui/icons-material'

import { ReactComponent as Logo } from '../images/logo-white.svg'
import AutoUserAvatar from './AutoUserAvatar'
import { useAuth } from 'lib/providers/AuthProvider'
import { isSsoEnabled } from 'lib/env'

const Offset = styled('div')(({ theme }) => theme.mixins.toolbar)

interface NavbarProps {
  showToggle?: boolean
  onToggle?: () => void
}
const Navbar: React.FC<NavbarProps> = ({ showToggle = false, onToggle }) => {
  const { t } = useTranslation()

  const [anchorElUser, setAnchorElUser] = React.useState<Element>(null)
  const navigate = useNavigate()
  const { logout, getLoggedIn, getLoggedInUsername } = useAuth()

  const loggedIn = getLoggedIn()
  const username = getLoggedInUsername()
  const ssoEnabled = isSsoEnabled()

  const LogInOutIcon = useMemo(() => (loggedIn ? LogoutIcon : LoginIcon), [loggedIn])

  const handleOpenUserMenu = useCallback((event: React.MouseEvent) => {
    setAnchorElUser(event.currentTarget)
  }, [])

  const handleCloseUserMenu = useCallback(() => {
    setAnchorElUser(null)
  }, [])

  const handleLogout = useCallback(() => {
    logout()
    navigate('/login', { replace: true })
    setAnchorElUser(null)
  }, [logout, navigate])

  // Hide the "Log in"/"Log out" menu if SSO is enabled but user is not authed
  const showNavMenu = !ssoEnabled || (ssoEnabled && loggedIn)

  return (
    <>
      <AppBar color="primary" enableColorOnDark>
        <Toolbar>
          <Collapse in={showToggle} orientation="horizontal">
            <IconButton onClick={onToggle} size="large" edge="start">
              <MenuIcon htmlColor="white" />
            </IconButton>
          </Collapse>

          <Logo
            style={{
              width: '165px',
              marginLeft: showToggle ? '20px' : 0
            }}
          />
          <Box flex={1} />

          <Tooltip title="User actions" placement="left">
            <IconButton onClick={handleOpenUserMenu} edge="end">
              <AutoUserAvatar name={username} sx={{ bgcolor: 'primary', color: 'text.primary' }} />
            </IconButton>
          </Tooltip>

          {showNavMenu && (
            <Menu
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right'
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right'
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
              sx={{
                '& .MuiAvatar-root': {
                  width: 32,
                  height: 32,
                  ml: -0.5,
                  mr: 1
                }
              }}>
              {loggedIn && (
                <MenuItem sx={{ pointerEvents: 'none' }}>
                  <AutoUserAvatar name={username} sx={{ width: 24, height: 24 }} />
                  {username}
                </MenuItem>
              )}
              {/* Menu specifically can't handle React fragments here, so use array instead */}
              {!ssoEnabled && [
                loggedIn && <Divider key="divider" />,
                <MenuItem onClick={handleLogout} key="logout">
                  <ListItemIcon>
                    <LogInOutIcon fontSize="small" />
                  </ListItemIcon>
                  <ListItemText>
                    {loggedIn
                      ? t('COMPONENTS.NAVBAR.BUTTON_LOG_OUT')
                      : t('COMPONENTS.NAVBAR.BUTTON_LOG_IN')}
                  </ListItemText>
                </MenuItem>
              ]}
            </Menu>
          )}
        </Toolbar>
      </AppBar>
      <Offset />
    </>
  )
}
export default Navbar
