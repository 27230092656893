import React, { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

import ReactGA from 'react-ga'
import { Metric, ReportHandler } from 'web-vitals'
import { getGoogleAnalyticsTag } from './env'

/**
 * Redact names when Google Analytics collects paths, such that
 * `/admin/users/view/admin` becomes `/admin/users/view/(name)`.
 *
 * Note that this currently assumes any 4th-level URL is a detail view.
 */
function anonymizePath(path?: string) {
  const splitPath = path?.split?.('/') ?? []
  if (splitPath.length < 4) {
    return path
  } else {
    return [...splitPath.slice(0, 4), '(name)'].join('/')
  }
}

/**
 * Send performance metrics to Google Analytics.
 *
 * See https://github.com/GoogleChrome/web-vitals#send-the-results-to-google-analytics
 */
function sendGoogleAnalyticsPerformanceMetric({ name, delta, id }: Metric) {
  const path = anonymizePath(window.location.pathname)
  ReactGA.event({
    category: 'Web Vitals',
    action: name,
    // The `id` value will be unique to the current page load. When sending
    // multiple values from the same page (e.g. for CLS), Google Analytics can
    // compute a total by grouping on this ID (note: requires `eventLabel` to
    // be a dimension in your report).
    label: id,
    // Google Analytics metrics must be integers, so the value is rounded.
    // For CLS the value is first multiplied by 1000 for greater precision
    // (note: increase the multiplier for greater precision if needed).
    value: Math.round(name === 'CLS' ? delta * 1000 : delta),
    nonInteraction: true,
    transport: 'beacon',
    dimension1: path
  })
}

async function reportWebVitals(onPerfEntry: ReportHandler) {
  if (onPerfEntry && onPerfEntry instanceof Function) {
    const { getCLS, getFID, getFCP, getLCP, getTTFB } = await import('web-vitals')

    getCLS(onPerfEntry)
    getFID(onPerfEntry)
    getFCP(onPerfEntry)
    getLCP(onPerfEntry)
    getTTFB(onPerfEntry)
  }
}

export function setupGoogleAnalytics() {
  const gaTag = getGoogleAnalyticsTag()
  if (gaTag) {
    ReactGA.initialize(gaTag)
    reportWebVitals(sendGoogleAnalyticsPerformanceMetric)
  }
}

/**
 * Watches for path changes and sends URLs visited to Google Analytics
 */
export const GoogleAnalyticsPageTracker: React.FC<React.PropsWithChildren<{}>> = ({ children }) => {
  const location = useLocation()
  const path = anonymizePath(location.pathname)

  useEffect(() => ReactGA.pageview(path), [path])

  return <>{children}</>
}

export default reportWebVitals
